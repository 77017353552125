import { GeneralLayout } from "../library/layout/General";
import { GridBlock } from "../library/components/grid/gridImages";
import {
  bloodDonationImages,
  seniorsMeetGreet,
  communityMeals,
  yogaImages,
  bloodDonationMelbourneImages,
} from "../utils/constants";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const style = {
  body1: {
    color: "#2f3191",
    fontSize: "1.4em",
    maxWidth: ["sm"],
    fontWeight: "600",
  },
  body2: {
    maxWidth: ["sm"],
    fontSize: "1em",
    fontWeight: "600",
  },
};

function Gallery(props) {
  return (
    <GeneralLayout
      title="Welcome | Shri Paramhans Advait Mat (Australia)"
      heading="Welcome"
    >
      <Typography variant="body1" align="left" sx={style.body1}>
        Blood Donation Camp - Sydney
      </Typography>
      <Stack spacing={6} justifyContent="center" alignItems="left">
        <GridBlock images={bloodDonationImages} />
      </Stack>

      <br />
      <br />
      <Typography variant="body1" align="left" sx={style.body1}>
        Blood Donation Camp - Melbourne
      </Typography>
      <Stack spacing={6} justifyContent="center" alignItems="left">
        <GridBlock images={bloodDonationMelbourneImages} />
      </Stack>
      <br />
      <br />
      <Typography variant="body1" align="left" sx={style.body1}>
        Free Community Meals - Sydney
      </Typography>
      <Stack spacing={6} justifyContent="center" alignItems="left">
        <GridBlock images={communityMeals} />
      </Stack>
      <br />
      <br />
      <Typography variant="body1" align="left" sx={style.body1}>
        Yoga and Meditation Session For All - Sydney
      </Typography>
      <Stack spacing={6} justifyContent="center" alignItems="left">
        <GridBlock images={yogaImages} />
      </Stack>
      <br />
      <br />
      <Typography variant="body1" align="left" sx={style.body1}>
        Seniors Meet and Greet - Sydney
      </Typography>
      <Stack spacing={6} justifyContent="center" alignItems="left">
        <GridBlock images={seniorsMeetGreet} />
      </Stack>
      <br />
      <br />
    </GeneralLayout>
  );
}

export default Gallery;
