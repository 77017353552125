import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "./components/Modal";
import { GlobalStyle } from "./globalStyles.js";
import { GeneralLayout } from "../library/layout/General";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import events from "../utils/Events";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Button = styled.button`
  min-width: 100px;
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
  background: #141414;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
`;

function App() {
  const [showModal, setShowModal] = useState(false);
  let [content, setContent] = useState(false);
  let [start, setStart] = useState(false);
  let [end, setEnd] = useState(false);
  const firstDaty = 1;
  const openModal = (info) => {
    const value = info.event.title;
    var startDate = new Date(info.event.start);
    var endDate = new Date(info.event.end);
    setShowModal((prev) => !prev);
    setContent((content) => value);
    setStart((start) => startDate.toLocaleTimeString().substring(0, 5));
    setEnd((end) => endDate.toLocaleTimeString().substring(0, 5));
  };

  return (
    <>
      <GeneralLayout
        title="Welcome | Shri Paramhans Advait Mat (Australia)"
        heading="Event Calendar | Shri Paramhans Advait Mat (Australia)"
      >
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          content={content}
          setContent={setContent}
          start={start}
          setStart={setStart}
          end={end}
          setEnd={setEnd}
        />

        <FullCalendar
          eventClick={(info) => {
            openModal(info);
          }}
          defaultView="dayGridMonth"
          firstDay={firstDaty}
          locale="en"
          header={{
            left: "prev,next",
            center: "title",
          }}
          themeSystem="Simplex"
          plugins={[dayGridPlugin]}
          events={events}
        />
      </GeneralLayout>
    </>
  );
}

export default App;
