import { Stack, Typography } from "@mui/material";
import { GeneralLayout } from "../library/layout/General";

const style = {
  h2: {
    fontSize: "1.2em",
    fontWeight: 500,
  },
  body2: {
    fontSize: "1em",
  },
};

function Privacy() {
  return (
    <GeneralLayout
      title="Priacy Policy | Shri Paramhans Advait Mat (Australia)"
      heading="Privacy Policy"
    >
      <Stack gap={4} sx={{ padding: "20px" }}>
        <Typography variant="body2" sx={style.body2}>
          This Privacy policy is subject to the Terms and Conditions of the
          Site. This policy will become effective as soon as user registers with
          the Site either by filling up the registration form and accepting the
          terms & conditions or on opening any of the Site’s pages or
          downloading any data on to any system by/through/on which the Site is
          accessed. To provide a user with better surfing experience and better
          results, the Site may collect personal information. To further improve
          the user experience, the Site may track the IP address of a user's
          computer or access device and save certain information on the user’s
          system in the form of cookies. A user has the option of either
          accepting or declining the cookies by changing the settings of the
          user’s browser.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          REGISTRATION:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          At the time of ‘registration’ a user will be required to furnish the
          details set out in the registration form. The Site does not share any
          sensitive user information with any third party. However, information
          of a general nature may be shared with external parties. A user always
          has the option of not providing the information which is not mandatory
          or does not feel comfortable in providing. The user is solely
          responsible for maintaining the confidentiality of his/her password,
          identification and all activities performed by the user through the
          user identification.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site is not responsible for a user’s identification and password,
          if a user fails in maintaining the secrecy of the login credentials.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The user agrees that the Site may use personal information about the
          user to:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          • Improve and to analyse the Site’s usage.
          <br /> • Improve the Site's content and product offerings.
          <br /> • Customize the Site's content, layout and services.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          PERSONAL INFORMATION:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The user agrees that the Site may use user’s personal information to
          contact the user and deliver information to the user that, in some
          cases, are targeted to the user’s interests, such as administrative
          notices, product offerings and communications relevant to the use of
          the Site. By accepting the Terms & Conditions and Privacy Policy, the
          user expressly agrees to receive this information. If the user does
          not wish to receive these communications, we encourage the user to opt
          out of the receipt of certain communications in his/her profile. The
          user may make these changes to his/her profile at any time.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          When user registers with the Site, we may contact the user from time
          to time about any updates to the Site, to provide the users such
          features that we believe may benefit the user.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          We may use the user email addresses to send periodic notifications
          about the service. Receivers/users can opt out of the same and will
          never receive any email from the Site.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          By using the Site, the user agrees to provide accurate information,
          and to cooperate with any Site’s personnel.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          While sending and receiving SMS messages, emails, calls etc. to or
          from the Site, we may collect and maintain information associated with
          those messages.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          "The user hereby acknowledges that the Site is not responsible for any
          intercepted information sent via the internet, and the user hereby
          releases the Site from all claims arising out of or related to the use
          of intercepted information in any unauthorized manner."
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          These terms and conditions are applicable on the user as soon as
          he/she lands/registers with the Site. The user also acknowledges that
          the user has read and understood all the terms and conditions related
          to the Site and agrees to it.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The content and information provided on/by the Site is for general
          information purpose only and the Site does not take any guarantee of
          correctness or appropriateness of such. The Site does not have any
          control over networking systems and servers; hence, the Site cannot
          control monitoring or data gathering services running on other
          systems. By creating an account on the Site the user acknowledges that
          the user has read, understood and accepted all the terms and
          condition, privacy policy and disclaimer by the Site.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site does not take any responsibility and will not be held
          accountable for any person or imposters who may communicate with a
          user via call, text, e-mail or by other means. Any user providing any
          information in such a case will be personally responsible and the Site
          shall not be held accountable.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site can be accessed by laptops, desktops, smartphones and other
          devices, hence, the Site will not be held accountable, if privacy
          issue or any other issue arises due to any of the system or device
          used to access the Site.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site will not be held accountable if any of the information
          provided on the Site is found false, incorrect, malefic, inappropriate
          or otherwise. The Site will not be held accountable for any loss,
          inconvenience etc. due to any information provided on any part or page
          of the Site, all users are advised to use information provided
          cautiously.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          By clicking on a post, offerings etc. the user acknowledges that the
          user information can be used for providing him/her with better
          service, etc. and hence, any mode of communication can be used by the
          Site.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site will not be held accountable for any loss, inconvenience etc.
          due to any information provided on any part or page of the Site, all
          users are advised to use information provided cautiously.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site may bar a user from accessing whole or any part of the Site,
          a user account can be sealed and a user registration can be cancelled
          by the Site without prior information, if a user is found to be
          performing any activity which may be illegal, suspicious,
          inappropriate, dangerous to the Site or any other type which is not
          suitable or acceptable in good sense.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site will not be accountable for any technical difficulties faced
          by a user before, after or during the time of accessing the Site. The
          Site does not take any guarantee of any copyright acts associated with
          any information or any material provided on the Site, in any such case
          the Site will not be held responsible or accountable.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          INFORMATION WE COLLECT:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          For accessing or using the Site, as appropriate, the user may be asked
          to enter the name, email address, contact/phone number, gender, age or
          other details to help the user with the user experience. The Site may
          collect demographic information about the user, or any other
          information provided by the user during the use of the Site. The Site
          might collect this as a part of a survey also. The Site may collect,
          inter alia, information about the user IP address, the browser user is
          using, the site user came from, duration of time spent on the Site,
          pages accessed or what site user visited when the user leaves the
          Site. The Site might also collect the type of mobile device the user
          is using or the operating system user’s computer or device is running.
          All information, disclosed by the user shall be deemed to be disclosed
          willingly and without any coercion. No liability pertaining to the
          authenticity, misrepresentation, fraud or negligence, etc. of the
          information disclosed to the Site shall lie on the Site nor will the
          Site in any way be responsible to verify any information obtained from
          the user.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          PURPOSE OF COLLECTING DATA:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          Collecting user’s data serves to provide the user with user friendly,
          efficient and secure internet services. The Site uses the collected
          and processed personal data in order to provide those users who are
          interested with information. Furthermore, the Site generally processes
          the data with the goal of improving the Site’s services and ensuring
          its security. No personal data is processed - the Site process the
          data anonymously or using pseudonyms. Whenever the user complete
          transactions using the Site, e.g. opening a user account, further data
          processing processes may take place, of which user will of course be
          separately informed.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site shall collect information from the user about user or
          relating to third parties, when the user, inter alia, signs in,
          registers, fills out a form, enters information or in general
          surfs/uses the Site. The Site collects information directly from the
          user when user contacts the Site. The Site also collects information
          if the user posts a comment or asks the Site a question through phone,
          email or Site. By signing-in on the Site and activating such data, the
          user has agreed that such data gathered by the Site is subject to this
          Policy and lawfully acquired. In addition to this we may make use of,
          inter alia, Google Analytics, New Relic, Google Search Console,
          browser cookies, web beacons, etc. to gather information about user
          and to make effective use of the information gathered from the user.
          The aforementioned technology tools shall collect only the information
          which the user voluntarily supplies and unless user identifies itself
          vide such information; the Site may not be able to ascertain the
          user’s identity. The user is free to delete the browser cookies at any
          time after logging out from the Site since they may or may not expire
          automatically on logging out. However, such cookies will be placed
          again on the user’s computers on login. Further, all information about
          the user, including information about the user’s internet connection,
          IP address, etc. may be used by the Site in the Site’s endeavours to
          cater to user’s interests, preferences and otherwise. All information
          gathered by the Site shall be stored on servers, log files and any
          other storage system owned by the Site or by third parties.
          Additionally, information may be stored on the Site’s computers, hard
          drives, hard copies, etc. The retention of such information will be
          till such time till a specific request is received from the user to
          delete such information. On the event that such request is made it
          shall be complied within 60 days except in case of data/information
          related to voluntary donations made from this site.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          THE FORM AND SCOPE OF COLLECTING AND PROCESSING PERSONAL DATA:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          If the user wishes to receive a newsletter, the Site needs to save and
          use user email address. The Site will then send electronic messages
          related to the other publications/eBooks available on the Site along
          with editorial information of such eBooks/publications, etc. at
          appropriate intervals to the email address user has provided. However,
          the user can unsubscribe from it at any time.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          USAGE DATA:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          As a general rule, the user may access the Site without having to
          provide any personal data. Nevertheless, the Site will create
          so-called utilization profile by using a pseudonym, if the user visits
          the Site as a registered or unregistered user. This implies that data
          will be collected and saved anonymously for providing the user best
          services. This data includes information on the Site from which the
          user was taken to the Site, information of the user internet service
          provider and the publications/eBooks, information the user has viewed
          on the Site, as well as date and duration of the user’s visit. On the
          basis of this pseudonymous data a utilization profile will be created,
          along with a corresponding pseudonym. For this purpose, cookies may be
          used. Authentication and tracking logs will be used to compile user
          statics. This data will not contain any personally identifiable
          information.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The user agrees and acknowledges that all information gathered from
          the user may be used by the Site for the following purposes:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          To personalize user's experience and to allow the Site to deliver the
          type of content and product offerings in which the user is most
          interested. The Site uses this information to look at Site trends and
          user interests. will use the user’s information.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          To maintain the Site and make it better; and to develop, protect and
          improve the Site’s Services; and manage the user’s
          account/subscriptions; and to allow the Site to provide better service
          to the user in responding to user’s service requests.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site might use the information about the communication regarding
          important notices or changes. It might also use the information to
          send periodic emails regarding a user’s subscription or other
          publications/eBooks and services.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site might use the information the user provides to contact the
          user for confirmation of registration on the Site, to respond to the
          user requests or questions, or for security purposes. The Site might
          use the information to protect the interests of the Site.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site might use the information to send user transactional
          communications. The Site might send user emails or SMS about user
          account or activity.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site might use the information for internal purposes such as
          auditing, data analysis and research relating to the Site and also for
          aggregating and developing metrics so as to improve user experience
          and to also facilitate contextual and non-contextual offerings from
          the Site and authorized third parties.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site might use the information as otherwise permitted by law.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          THIRD PARTY SERVICES:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site does not sell, trade, or otherwise transfer to outside
          parties. In general, the third-party providers used by the Site will
          only collect, use and disclose user information to the extent
          necessary to allow them to perform the services they provide to the
          Site. However, certain third-party service providers have their own
          privacy policies in respect to the information the Site is required to
          provide to them. For these providers, the Site recommends that user
          shall read their privacy policies so that the user can understand the
          manner in which their personal information will be handled by these
          providers. In particular, remember that certain providers may be
          located in or have facilities that are located at a different
          jurisdiction than either the user or the Site. So, if the user elects
          to proceed with an event that involves the services of a third-party
          service provider, then the user information may become subject to the
          laws of the jurisdiction(s) in which that service provider or its
          facilities are located.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site only passes on personal data to a third party to the extent
          necessary to fulfil the Site’s contractual relationship (if at all),
          if the Site are bound by law or if it should be necessary in order to
          implement the general terms and conditions of business or any other
          agreements, or in order to enforce the Site’s rights and claims.
          Provided the user’s personal data is passed on for other purposes, the
          Site will ask the user for approval in each individual case. If user
          do not approve, the Site will of course not pass on the data.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site may also include links to third party sites. When the user
          clicks on such links on the Site, they may direct user away from the
          Site. The Site is not responsible for the privacy practices of other
          sites and encourages the user to read their privacy statements. Once
          user leaves the Site or is redirected to a third-party site or
          application, the user is no longer governed by this Privacy Policy or
          the Site’s Terms of Use.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site may share the sensitive personal information to any third
          party without obtaining the prior consent of the User in the following
          limited circumstances:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          When it is requested or required by law or by any Court or
          governmental Agency or Authority to disclose, for the purpose of
          verification of identity, or for the prevention, detection,
          investigation including cyber incidents, or for prosecution and
          punishment of the offences.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site proposes to share such information with its co-sites for
          providing better services to the users.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          All required information is service dependent, and the Site may use
          the user information to maintain, protect, and improve its services
          and for developing new services.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          DISCLOSURE, RECTIFICATION, DELETION, ACCOUNT FREEZING:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site may disclose a user’s personal information if required by law
          to do so or if the users violate to any of the Site’s Terms of
          Use/Policy. The user agrees that if so happens or as per the
          directions of any governmental agency, authority, court, etc. the Site
          may be required to disclose all users’ information and in such an
          event the Site is in no manner responsible for informing the user or
          for seeking the user’s prior approval.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          Provided the user sends a request in writing, the Site will not inform
          the user about which personal data the Site has saved at any time. The
          user may at any time demand that the Site freeze or delete the user
          account data and accordingly the user personal data. Only data the
          Site needs in order to process open tasks or to enforce the Site’s
          right is exempt from deletion, as well as the data the Site is
          required to save by law.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          COOKIES, ETC.:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site may use "cookies", or similar electronic tools to track the
          users coming to the Site. Web servers may automatically collect
          information about the user computer or device for connection. The Site
          does not have any control over it; hence, a user acknowledges that
          they have no issues in such a kind of information gathering, and the
          Site will not be held responsible/accountable for the same.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site uses so called cookies or other instruments to analyse the
          usage of the Site. Cookies are small files which are saved on the
          user’s hard drive and provide the Site with specific information. This
          information, above all, includes the IP address, the user’s browser
          type, the URL click stream (the chronological order of the Site’s
          internet sites/pages the user has visited), the date and the time of
          the user’s visit on the Site as well as the cookie number. Firstly,
          using cookies and similar instruments makes it possible to recognize
          the user whenever user accesses the internet sites again after the
          expiration of a session. Cookies also allow the Site to ideally adapt
          the Site’s eBooks/publications and services, etc. to the user’s
          individual needs. Lastly, the Site use cookies to statistically record
          how often the internet sites are viewed, as well as the general
          navigation in order to create encoded usage profiles.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          SECURITY:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site may or may not include links to other websites or other
          portals. Such sites are governed by their respective privacy policies,
          which are beyond our control. Once the user leaves the Site, the use
          of any information provided by the user is governed by the privacy
          policy of the operator of the site being visited and the policy may
          differ from the Site’s.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          It should be noted that no security system is impenetrable, hence, the
          Site cannot guarantee the security of database, nor can the Site
          guarantee that information the user is supplying will not be
          intercepted while being transmitted to the Site over the Internet.
          And, of course, any information the user includes in a posting to the
          discussion areas is available to anyone with Internet access.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          However, the Site employs technical and organizational security
          measures to guarantee that the user data is protected from loss,
          incorrect modifications and unauthorized third-party access. To the
          greatest extent possible, the Site ensures that only authorized
          persons will have access to the user’s personal data and only insofar
          as it is necessary in the above-mentioned purposes.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          PROTECTION OF INFORMATION:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          To protect a user’s personal information, the Site takes reasonable
          precautions and follows best practices to make sure it is not
          inappropriately lost, misused, accessed, disclosed, altered or
          destroyed. The Site implements a variety of security measures when a
          user enters, submits, or accesses their information to maintain the
          safety of user’s personal information. The user’s personal information
          is contained behind secured networks and is only accessible to a
          limited number of persons who have special access rights to such
          systems and are required to keep the information confidential. In
          addition, all sensitive information user supply is encrypted via
          Secure Socket Layer (SSL) technology.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          EMAIL OPT-OUT:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The user can opt out of receiving the Site’s emails. If at any time, a
          user wants to unsubscribe from receiving future emails, he/she can
          email the Site or use unsubscribe functionality given on the Site for
          the respective eBook/Journal/Service and the Site will promptly remove
          user from the related correspondence. It may take about ten days to
          process the user’s request. Even if user opts out of getting
          eBooks/Publications/Services messages, the Site may still be sending
          you important messages through email and/or SMS.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          AMENDMENTS/CHANGES/CLARIFICATIONS:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The Site retains unconditional right to modify or amend this Privacy
          Policy without any recourse to user or any requirement of notice of
          the same to user. The changes and clarifications will take effect
          immediately upon their posting on the Site. The user is encouraged to
          keep checking the privacy policy, terms and conditions and disclaimers
          for any such changes on an occasional basis.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          JURISDICTION:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          If a user chooses to visit the Site; user’s visit and any dispute over
          privacy is subject to this policy and the terms of use of the Site. In
          addition to the foregoing, any disputes arising under this policy
          shall be governed by the laws of Australia.
        </Typography>
        <Typography variant="h2" sx={style.h2}>
          CONTACTING US:
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          When the user uses the Site, if any information is found to be
          inaccurate or deficient it shall be corrected or amended as feasible,
          the Site may decline to process requests that are unreasonably
          repetitive or systematic, require disproportionate technical effort,
          jeopardize the privacy of others, or may be seen impractical by the
          Site’s work team. If user has any complaint, concerns in context of
          content, comment, information provided on the Site, picture, images,
          terms and conditions, privacy policy, disclaimer or otherwise, he/she
          may inform/contact the Site through the contact form provided on the
          website.
        </Typography>
      </Stack>
    </GeneralLayout>
  );
}

export default Privacy;
