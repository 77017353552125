import { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { GeneralLayout } from "../library/layout/General";
import { DonationForm } from "../library/components/forms/Donation";
import { CheckoutForm } from "../library/components/forms/Checkout";
import { PaymentModal } from "../library/components/modals/Payment";
import Box from "@mui/material/Box";

const FINALISE_DONATION = gql`
  mutation FinaliseDonation($input: donationInputs) {
    donation: finaliseDonation(input: $input) {
      id
      clientSecret
    }
  }
`;

const Key = process.env.REACT_APP_STRIPE_PROD;

const stripePromise = loadStripe(Key); // publishable key

function Donate(props) {
  const [clientSecret, setClientSecret] = useState("");
  const [finalising, setFinalising] = useState(false);

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#32b78d",
      colorText: "#000000",
      fontWeight: "bold",
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  const [finaliseDonation, { loading, data, error }] =
    useMutation(FINALISE_DONATION);

  useEffect(() => {
    if (data) {
      console.log(data, "response");
      setClientSecret(data?.donation?.clientSecret);
      setFinalising(true);
    }
  }, [data]);

  useEffect(() => {
    if (error) console.log(error, "error");
  }, [error]);

  return (
    <GeneralLayout
      title="Donation | Shri Paramhans Advait Mat (Australia)"
      heading="Donation"
    >
      <Box
        sx={{
          maxWidth: "sm",
          mx: "auto",
          background: "#ffffff",
          padding: "30px",
          borderRadius: "10px",
        }}
      >
        <DonationForm finalise={finaliseDonation} loader={loading} />
        <PaymentModal state={finalising} update={setFinalising}>
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </PaymentModal>
      </Box>
    </GeneralLayout>
  );
}

export default Donate;
