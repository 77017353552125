import * as yup from "yup";
import { purposeVals, countryVals } from "./constants";

export const donationSchema = yup.object().shape({
  amount: yup.string("Enter a valid input").required("Must enter an amount"),
  purpose: yup
    .string("Select a purpose")
    .required("Purpose is required")
    .oneOf([...purposeVals]),
  donor: yup
    .string("Select a type")
    .required("Type is required")
    .oneOf(["Individual", "Organisation"]),
  name: yup.string("Enter name").required("Name is required"),
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  phone: yup
    .string("Enter a phone number")
    .min(10, "Must be valid phone number")
    .required("Phone is required"),
  dob: yup.string("Enter Date of Birth").optional(),
  abn: yup.string("Enter ABN").nullable().optional(),
  street: yup
    .string("Enter street address")
    .required("Street address is required"),
  addtional: yup.string("Enter addtional address line"),
  suburb: yup.string("Enter suburb").required("Suburb is requried"),
  state: yup.string("Enter state").required("State is required"),
  postcode: yup.string("Enter postcode").required("Postcode is required"),
  country: yup
    .object()
    .shape({
      code: yup.string(),
      label: yup.string(),
      phone: yup.string(),
    })
    .nullable()
    .required("Country is required")
    .oneOf([...countryVals]),
});
