import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Donate from "./pages/Donate";
import Gallery from "./pages/Gallery";
import Events from "./pages/Events";
import Success from "./pages/Success";
import Privacy from "./pages/Privacy";
import Error from "./pages/Error";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/donate" element={<Donate />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/success" element={<Success />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/events" element={<Events />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default App;
