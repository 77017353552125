import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Logo from "../../logo.png";
import Bg from "../../bg.webp";

const year = new Date().getFullYear();

const theme = createTheme({
  palette: {
    primary: {
      main: "#32b78d",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#feb63c",
      contrastText: "#ffffff",
    },
    background: {
      default: "#feb63c",
    },
  },
});

const styles = {
  main: {
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    paddingBottom: "80px",
    minHeight: `calc(100vh - 140px)`,
  },
  header: {
    textAlign: "center",
  },
  spacer: {
    minHeight: "20vw",
  },
  footer: {
    background: "#32b78d",
    padding: "5px",
    color: "#ffffff",
    textAlign: "center",
  },
};

export const GeneralLayout = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <main style={styles.main}>
        <div>
          <div style={styles.spacer}></div>
          <header style={styles.header}>
            <Link to="/">
              <img src={Logo} alt="Logo" width="160px" />
            </Link>
            <Typography
              variant="h1"
              sx={{
                fontSize: "2.5em",
                fontWeight: "bold",
                color: "#238149",
                marginBottom: "20px",
              }}
            >
              {props.heading}
            </Typography>
          </header>
          <article>
            <Container maxWidth="md">
              <div>{props.children}</div>
            </Container>
          </article>
        </div>
      </main>
      <footer style={styles.footer}>
        <Container maxWidth="md">
          <p>
            All Rights Reserved, © {year} - Shri Paramhans Advait Mat Australia
            |{" "}
            <Link to="/privacy" style={{ color: "white" }}>
              Privacy Policy
            </Link>
          </p>
        </Container>
      </footer>
    </ThemeProvider>
  );
};
