import React from "react";
import Gallery from "react-grid-gallery";
import Box from "@mui/material/Box";

const style = {
  position: "relative",
  align: "center",
};
export function GridBlock(props) {
  const { images } = props;

  return (
    <Box sx={style}>
      <Gallery images={images} enableLightbox={true} backdropClosesModal />
    </Box>
  );
}
