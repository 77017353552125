import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

export const SliderBlock = (props) => {
  const { images } = props;

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {images &&
          images.map((img, index) => (
            <SwiperSlide key={index}>
              <img src={img.src} alt="Land" width="100%" height="100%" />
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};
