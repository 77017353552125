const events = [
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-08-29T10:00:00",
    end: "2022-08-29T12:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-08-29T16:00:00",
    end: "2022-08-29T18:00:00",
    backgroundColor: "pink",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-08-29T18:00:00",
    end: "2022-08-29T20:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-08-29T18:00:00",
    end: "2022-08-29T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-08-30T10:00:00",
    end: "2022-08-30T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-08-30T12:00:00",
    end: "2022-08-30T16:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-08-30T18:00:00",
    end: "2022-08-30T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-08-31T10:00:00",
    end: "2022-08-31T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-08-31T18:00:00",
    end: "2022-08-31T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-01T10:00:00",
    end: "2022-09-01T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-01T18:00:00",
    end: "2022-09-01T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-02T10:00:00",
    end: "2022-09-02T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-02T18:00:00",
    end: "2022-09-02T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-03T08:30:00",
    end: "2022-09-03T10:30:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-03T11:00:00",
    end: "2022-09-03T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-04T06:30:00",
    end: "2022-09-04T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-04T07:30:00",
    end: "2022-09-04T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-04T08:30:00",
    end: "2022-09-04T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-04T11:00:00",
    end: "2022-09-04T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Blood Donation - pre-organised with Blood Bank",
    start: "2022-09-04T15:00:00",
    end: "2022-09-04T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-09-05T10:00:00",
    end: "2022-09-05T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-09-05T16:00:00",
    end: "2022-09-05T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-09-05T18:00:00",
    end: "2022-09-05T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-05T18:00:00",
    end: "2022-09-05T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-09-06T10:00:00",
    end: "2022-09-06T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-06T18:00:00",
    end: "2022-09-06T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-09-07T10:00:00",
    end: "2022-09-07T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-07T18:00:00",
    end: "2022-09-07T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-09-08T10:00:00",
    end: "2022-09-08T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-08T18:00:00",
    end: "2022-09-08T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-09-09T10:00:00",
    end: "2022-09-09T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-09T18:00:00",
    end: "2022-09-09T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-09-10T08:30:00",
    end: "2022-09-10T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-10T11:00:00",
    end: "2022-09-10T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-11T06:30:00",
    end: "2022-09-11T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-11T07:30:00",
    end: "2022-09-11T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-11T08:30:00",
    end: "2022-09-11T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-11T11:00:00",
    end: "2022-09-11T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Blood Donation - pre-organised with Blood Bank",
    start: "2022-09-11T15:00:00",
    end: "2022-09-11T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-09-12T10:00:00",
    end: "2022-09-12T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-09-12T16:00:00",
    end: "2022-09-12T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-09-12T18:00:00",
    end: "2022-09-12T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-12T18:00:00",
    end: "2022-09-12T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-09-13T10:00:00",
    end: "2022-09-13T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-13T18:00:00",
    end: "2022-09-13T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-09-14T10:00:00",
    end: "2022-09-14T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-14T18:00:00",
    end: "2022-09-14T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-09-15T10:00:00",
    end: "2022-09-15T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-15T18:00:00",
    end: "2022-09-15T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-09-16T10:00:00",
    end: "2022-09-16T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-16T18:00:00",
    end: "2022-09-16T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-09-17T08:30:00",
    end: "2022-09-17T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-17T11:00:00",
    end: "2022-09-17T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-18T06:30:00",
    end: "2022-09-18T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-18T07:30:00",
    end: "2022-09-18T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-18T08:30:00",
    end: "2022-09-18T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-09-18T11:00:00",
    end: "2022-09-18T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Blood Donation - pre-organised with Blood Bank",
    start: "2022-09-18T15:00:00",
    end: "2022-09-18T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-09-19T10:00:00",
    end: "2022-09-19T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-09-19T16:00:00",
    end: "2022-09-19T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-09-19T18:00:00",
    end: "2022-09-19T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-19T18:00:00",
    end: "2022-09-19T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-09-20T10:00:00",
    end: "2022-09-20T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-20T18:00:00",
    end: "2022-09-20T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-09-21T10:00:00",
    end: "2022-09-21T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-21T18:00:00",
    end: "2022-09-21T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-09-22T10:00:00",
    end: "2022-09-22T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-22T18:00:00",
    end: "2022-09-22T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-09-23T10:00:00",
    end: "2022-09-23T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-23T18:00:00",
    end: "2022-09-23T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-09-24T08:30:00",
    end: "2022-09-24T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-24T11:00:00",
    end: "2022-09-24T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-09-25T08:30:00",
    end: "2022-09-25T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-25T11:00:00",
    end: "2022-09-25T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-09-26T10:00:00",
    end: "2022-09-26T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-09-26T16:00:00",
    end: "2022-09-26T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-09-26T18:00:00",
    end: "2022-09-26T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-26T18:00:00",
    end: "2022-09-26T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-09-27T10:00:00",
    end: "2022-09-27T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title:
      "Monthly seniors meet - Lecture provided by professionals covering health and dietary requirements",
    start: "2022-09-27T12:00:00",
    end: "2022-09-27T16:00:00",
    backgroundColor: "sky lightblue",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-27T18:00:00",
    end: "2022-09-27T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-09-28T10:00:00",
    end: "2022-09-28T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-28T18:00:00",
    end: "2022-09-28T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-09-29T10:00:00",
    end: "2022-09-29T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-29T18:00:00",
    end: "2022-09-29T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-09-30T10:00:00",
    end: "2022-09-30T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-09-30T18:00:00",
    end: "2022-09-30T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-10-01T08:30:00",
    end: "2022-10-01T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-01T11:00:00",
    end: "2022-10-01T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-02T06:30:00",
    end: "2022-10-02T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-02T07:30:00",
    end: "2022-10-02T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-02T08:30:00",
    end: "2022-10-02T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-02T11:00:00",
    end: "2022-10-02T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Blood Donation - pre-organised with Blood Bank",
    start: "2022-10-02T15:00:00",
    end: "2022-10-02T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-10-03T10:00:00",
    end: "2022-10-03T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-03T16:00:00",
    end: "2022-10-03T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-03T18:00:00",
    end: "2022-10-03T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-03T18:00:00",
    end: "2022-10-03T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-10-04T10:00:00",
    end: "2022-10-04T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-04T18:00:00",
    end: "2022-10-04T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-10-05T10:00:00",
    end: "2022-10-05T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-05T18:00:00",
    end: "2022-10-05T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-10-06T10:00:00",
    end: "2022-10-06T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-06T18:00:00",
    end: "2022-10-06T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-10-07T10:00:00",
    end: "2022-10-07T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-07T18:00:00",
    end: "2022-10-07T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-10-08T08:30:00",
    end: "2022-10-08T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-08T11:00:00",
    end: "2022-10-08T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-09T06:30:00",
    end: "2022-10-09T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-09T07:30:00",
    end: "2022-10-09T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-09T08:30:00",
    end: "2022-10-09T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-09T11:00:00",
    end: "2022-10-09T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Coloaborate with local authorities for cleaning up park and community facilities",
    start: "2022-10-09T15:00:00",
    end: "2022-10-09T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free plants distribution for Green Australia and increase awareness of climate",
    start: "2022-10-10T10:00:00",
    end: "2022-10-10T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-10T16:00:00",
    end: "2022-10-10T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-10T18:00:00",
    end: "2022-10-10T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-10T18:00:00",
    end: "2022-10-10T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-10-11T10:00:00",
    end: "2022-10-11T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-11T18:00:00",
    end: "2022-10-11T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-10-12T10:00:00",
    end: "2022-10-12T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-12T18:00:00",
    end: "2022-10-12T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-10-13T10:00:00",
    end: "2022-10-13T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-13T18:00:00",
    end: "2022-10-13T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-10-14T10:00:00",
    end: "2022-10-14T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-14T18:00:00",
    end: "2022-10-14T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-10-15T08:30:00",
    end: "2022-10-15T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-15T11:00:00",
    end: "2022-10-15T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-16T06:30:00",
    end: "2022-10-16T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-16T07:30:00",
    end: "2022-10-16T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-16T08:30:00",
    end: "2022-10-16T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-16T11:00:00",
    end: "2022-10-16T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Blood Donation - pre-organised with Blood Bank",
    start: "2022-10-16T15:00:00",
    end: "2022-10-16T16:00:00",
    backgroundColor: "lightblue",
  },

  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-10-17T10:00:00",
    end: "2022-10-17T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-17T16:00:00",
    end: "2022-10-17T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-17T18:00:00",
    end: "2022-10-17T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-17T18:00:00",
    end: "2022-10-17T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-10-18T10:00:00",
    end: "2022-10-18T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-18T18:00:00",
    end: "2022-10-18T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-10-19T10:00:00",
    end: "2022-10-19T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-19T18:00:00",
    end: "2022-10-19T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-10-20T10:00:00",
    end: "2022-10-20T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-20T18:00:00",
    end: "2022-10-20T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-10-21T10:00:00",
    end: "2022-10-21T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-21T18:00:00",
    end: "2022-10-21T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-10-22T08:30:00",
    end: "2022-10-22T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-22T11:00:00",
    end: "2022-10-22T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-23T08:30:00",
    end: "2022-10-23T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-23T11:00:00",
    end: "2022-10-23T14:00:00",
    backgroundColor: "lightblue",
  },

  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-10-24T10:00:00",
    end: "2022-10-24T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-24T16:00:00",
    end: "2022-10-24T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-24T18:00:00",
    end: "2022-10-24T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-24T18:00:00",
    end: "2022-10-24T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-10-25T10:00:00",
    end: "2022-10-25T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title:
      "Monthly seniors meet - Lecture provided by professionals covering health and dietary requirements",
    start: "2022-10-25T12:00:00",
    end: "2022-10-25T16:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-25T18:00:00",
    end: "2022-10-25T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-10-26T10:00:00",
    end: "2022-10-26T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-26T18:00:00",
    end: "2022-10-26T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-10-27T10:00:00",
    end: "2022-10-27T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-27T18:00:00",
    end: "2022-10-27T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-10-28T10:00:00",
    end: "2022-10-28T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-28T18:00:00",
    end: "2022-10-28T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-29T08:30:00",
    end: "2022-10-29T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-29T11:00:00",
    end: "2022-10-29T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-30T06:30:00",
    end: "2022-10-30T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-10-30T07:30:00",
    end: "2022-10-30T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-10-30T08:30:00",
    end: "2022-10-30T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-30T11:00:00",
    end: "2022-10-30T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-10-31T10:00:00",
    end: "2022-10-31T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-31T16:00:00",
    end: "2022-10-31T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-10-31T18:00:00",
    end: "2022-10-31T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-10-31T18:00:00",
    end: "2022-10-31T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-01T10:00:00",
    end: "2022-11-01T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Monthly seniors meet - Lecture provided by professionals covering health and dietary requirements",
    start: "2022-11-01T12:00:00",
    end: "2022-11-01T16:00:00",
    backgroundColor: "sky lightblue",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-01T18:00:00",
    end: "2022-11-01T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-11-02T10:00:00",
    end: "2022-11-02T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-02T18:00:00",
    end: "2022-11-02T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-03T10:00:00",
    end: "2022-11-03T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-03T18:00:00",
    end: "2022-11-03T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-04T10:00:00",
    end: "2022-11-04T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-04T18:00:00",
    end: "2022-11-04T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-05T08:30:00",
    end: "2022-11-05T10:30:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-05T11:00:00",
    end: "2022-11-05T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-06T06:30:00",
    end: "2022-11-06T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-06T07:30:00",
    end: "2022-11-06T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-06T08:30:00",
    end: "2022-11-06T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-06T11:00:00",
    end: "2022-11-06T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Blood Donation - pre-organised with Blood Bank",
    start: "2022-11-04T15:00:00",
    end: "2022-11-04T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-11-07T10:00:00",
    end: "2022-11-07T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-11-07T16:00:00",
    end: "2022-11-07T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-11-07T18:00:00",
    end: "2022-11-07T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-07T18:00:00",
    end: "2022-11-07T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-11-08T10:00:00",
    end: "2022-11-08T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-08T18:00:00",
    end: "2022-11-08T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-11-09T10:00:00",
    end: "2022-11-09T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-09T18:00:00",
    end: "2022-11-09T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-10T10:00:00",
    end: "2022-11-10T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-10T18:00:00",
    end: "2022-11-10T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-11T10:00:00",
    end: "2022-11-11T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-11T18:00:00",
    end: "2022-11-11T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-12T08:30:00",
    end: "2022-11-12T10:30:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-12T11:00:00",
    end: "2022-11-12T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-13T06:30:00",
    end: "2022-11-013T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-13T07:30:00",
    end: "2022-11-13T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-13T08:30:00",
    end: "2022-11-13T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-13T11:00:00",
    end: "2022-11-13T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Coloaborate with local authorities for cleaning up park and community facilities",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-13T15:00:00",
    end: "2022-11-13T16:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Coloaborate with local authorities for cleaning up park and community facilities",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-14T10:00:00",
    end: "2022-11-14T12:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-11-14T16:00:00",
    end: "2022-11-14T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-11-14T18:00:00",
    end: "2022-11-14T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-14T18:00:00",
    end: "2022-11-14T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-11-15T10:00:00",
    end: "2022-11-15T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-15T18:00:00",
    end: "2022-11-15T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-11-16T10:00:00",
    end: "2022-11-16T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-16T18:00:00",
    end: "2022-11-16T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-17T10:00:00",
    end: "2022-11-17T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-17T18:00:00",
    end: "2022-11-17T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-18T10:00:00",
    end: "2022-11-18T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-18T18:00:00",
    end: "2022-11-18T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-19T08:30:00",
    end: "2022-11-19T10:30:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-19T11:00:00",
    end: "2022-11-19T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-20T06:30:00",
    end: "2022-11-20T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-20T07:30:00",
    end: "2022-11-20T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-20T08:30:00",
    end: "2022-11-20T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-20T11:00:00",
    end: "2022-11-20T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Blood Donation - pre-organised with Blood Bank",
    start: "2022-11-20T15:00:00",
    end: "2022-11-20T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-11-21T10:00:00",
    end: "2022-11-21T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-11-21T16:00:00",
    end: "2022-11-21T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-11-21T18:00:00",
    end: "2022-11-21T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-21T18:00:00",
    end: "2022-11-21T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-11-22T10:00:00",
    end: "2022-11-22T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-22T18:00:00",
    end: "2022-11-22T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-11-23T10:00:00",
    end: "2022-11-23T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-23T18:00:00",
    end: "2022-11-23T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-24T10:00:00",
    end: "2022-11-24T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-24T18:00:00",
    end: "2022-11-24T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-25T10:00:00",
    end: "2022-11-25T14:00:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-25T18:00:00",
    end: "2022-11-18T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-26T08:30:00",
    end: "2022-11-26T10:30:00",
    backgroundColor: "brown",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-26T11:00:00",
    end: "2022-11-26T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-27T08:30:00",
    end: "2022-11-27T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-11-27T11:00:00",
    end: "2022-11-27T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-11-28T10:00:00",
    end: "2022-11-28T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-11-28T16:00:00",
    end: "2022-11-28T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-11-28T18:00:00",
    end: "2022-11-28T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-28T18:00:00",
    end: "2022-11-28T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-11-29T10:00:00",
    end: "2022-11-29T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title:
      "Monthly seniors meet - Lecture provided by professionals covering health and dietary requirements",
    start: "2022-11-29T12:00:00",
    end: "2022-11-29T16:00:00",
    backgroundColor: "sky lightblue",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-29T18:00:00",
    end: "2022-11-29T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-11-30T10:00:00",
    end: "2022-11-30T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-11-30T18:00:00",
    end: "2022-11-30T21:00:00",
    backgroundColor: "lightblue",
  },

  // DECEMBER Events
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-01T10:00:00",
    end: "2022-12-01T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-01T18:00:00",
    end: "2022-12-01T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-02T10:00:00",
    end: "2022-12-02T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-02T18:00:00",
    end: "2022-12-02T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-12-03T08:30:00",
    end: "2022-12-03T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-12-03T11:00:00",
    end: "2022-12-03T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-12-04T06:30:00",
    end: "2022-12-04T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-12-04T07:30:00",
    end: "2022-12-04T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-12-04T08:30:00",
    end: "2022-12-04T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-04T11:00:00",
    end: "2022-12-04T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Blood Donation - pre-organised with Blood Bank",
    start: "2022-12-04T15:00:00",
    end: "2022-12-04T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-12-05T10:00:00",
    end: "2022-12-05T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12am) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-12-05T16:00:00",
    end: "2022-12-05T18:00:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-12-05T18:00:00",
    end: "2022-12-05T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-05T18:00:00",
    end: "2022-12-05T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-12-06T10:00:00",
    end: "2022-12-06T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-06T18:00:00",
    end: "2022-12-06T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-12-07T10:00:00",
    end: "2022-12-07T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-07T18:00:00",
    end: "2022-12-07T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-08T10:00:00",
    end: "2022-12-08T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-08T18:00:00",
    end: "2022-12-08T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-09T10:00:00",
    end: "2022-12-09T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-09T18:00:00",
    end: "2022-12-09T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-10T10:00:00",
    end: "2022-12-10T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-10T18:00:00",
    end: "2022-12-10T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-12-11T06:30:00",
    end: "2022-12-11T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-12-11T07:30:00",
    end: "2022-12-11T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-12-11T08:30:00",
    end: "2022-12-11T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-11T11:00:00",
    end: "2022-12-11T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Coloaborate with local authorities for cleaning up park and community facilities",
    start: "2022-12-11T15:00:00",
    end: "2022-12-11T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free plants distribution for Green Australia and increase awareness of climate",
    start: "2022-12-12T10:00:00",
    end: "2022-12-12T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12years) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-12-12T16:00:00",
    end: "2022-12-12T18:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-12-12T18:00:00",
    end: "2022-12-12T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-12T18:00:00",
    end: "2022-12-12T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-12-13T08:30:00",
    end: "2022-12-13T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-13T18:00:00",
    end: "2022-12-13T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-12-14T10:00:00",
    end: "2022-12-14T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-14T18:00:00",
    end: "2022-12-14T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-15T10:00:00",
    end: "2022-12-15T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-15T18:00:00",
    end: "2022-12-15T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-16T10:00:00",
    end: "2022-12-16T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-16T18:00:00",
    end: "2022-12-16T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-17T10:00:00",
    end: "2022-12-17T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-17T18:00:00",
    end: "2022-12-17T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Meditation and Yoga for mental health for adults/seniors",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-12-18T06:30:00",
    end: "2022-12-18T07:30:00",
    backgroundColor: "brown",
  },
  {
    title:
      "Meditation and Yoga for mental health for under 18s (accompanied by adults)",
    // start: getDate("YEAR-MONTH-02"),
    start: "2022-12-18T07:30:00",
    end: "2022-12-18T08:30:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-12-18T08:30:00",
    end: "2022-12-18T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-18T11:00:00",
    end: "2022-12-18T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title: "Blood Donation - pre-organised with Blood Bank",
    start: "2022-12-18T15:00:00",
    end: "2022-12-18T16:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-12-19T10:00:00",
    end: "2022-12-19T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12years) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-12-19T16:00:00",
    end: "2022-12-19T18:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-12-19T18:00:00",
    end: "2022-12-19T20:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-19T18:00:00",
    end: "2022-12-19T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-12-20T08:30:00",
    end: "2022-12-20T10:30:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-20T18:00:00",
    end: "2022-12-20T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-12-21T10:00:00",
    end: "2022-12-21T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-21T18:00:00",
    end: "2022-12-21T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-22T10:00:00",
    end: "2022-12-22T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-22T18:00:00",
    end: "2022-12-22T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-23T10:00:00",
    end: "2022-12-23T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-23T18:00:00",
    end: "2022-12-23T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-24T10:00:00",
    end: "2022-12-24T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-24T18:00:00",
    end: "2022-12-24T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free counselling and psychology services by Jason Abbas Aramideh By appointment only",
    start: "2022-12-25T08:30:00",
    end: "2022-12-25T10:30:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-25T11:00:00",
    end: "2022-12-25T14:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Work with local community bodies in re-developing the areas impacted by fire and floods",
    start: "2022-12-26T10:00:00",
    end: "2022-12-26T12:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free occupational therapy for juniors (under 12years) by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-12-26T16:00:00",
    end: "2022-12-26T18:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title:
      "Free occupational therapy for teens by Vanessa Fusitua and Emiley Yates By appointment only",
    start: "2022-12-26T18:00:00",
    end: "2022-12-12620:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-26T18:00:00",
    end: "2022-12-26T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free Physiotherapy, in-house session by Luke Kluderovic By appointment only",
    start: "2022-12-27T08:30:00",
    end: "2022-12-27T10:30:00",
    backgroundColor: "pink",
  },
  {
    title:
      "Monthly seniors meet - Lecture provided by professionals covering health and dietary requirements",
    start: "2022-12-27T12:00:00",
    end: "2022-12-27T16:00:00",
    backgroundColor: "pink",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-27T18:00:00",
    end: "2022-12-27T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free speech and language pathology, in-house session by Amelia Bayliss and Vanessa Chan By appointment only",
    start: "2022-12-28T10:00:00",
    end: "2022-12-28T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-28T18:00:00",
    end: "2022-12-28T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-29T10:00:00",
    end: "2022-12-29T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-29T18:00:00",
    end: "2022-12-29T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-30T10:00:00",
    end: "2022-12-30T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-30T18:00:00",
    end: "2022-12-30T21:00:00",
    backgroundColor: "lightblue",
  },
  {
    title:
      "Free rehabilitation and community physiotherapy by Gina Tieu and Sharan Singh By appointment only",
    start: "2022-12-31T10:00:00",
    end: "2022-12-31T14:00:00",
    backgroundColor: "lightgreen",
  },
  {
    title: "Free community meal/ clothing/groceries/masks/hand sanitisers",
    start: "2022-12-31T18:00:00",
    end: "2022-12-31T21:00:00",
    backgroundColor: "lightblue",
  },
];

export default events;
