import { useNavigate } from "react-router-dom";
import { GeneralLayout } from "../library/layout/General";
import { SliderBlock } from "../library/components/blocks/Slider";
import { landImages } from "../utils/constants";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Marquee from "react-fast-marquee";
import Link from "@mui/material/Link";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";

const style = {
  body1: {
    color: "#2f3191",
    fontSize: "1.4em",
    maxWidth: ["sm"],
    fontWeight: "600",
  },
  body2: {
    maxWidth: ["sm"],
    fontSize: "1em",
    fontWeight: "600",
  },
  marquee: {
    backgroundColor: "#238149",
    color: "#ffffff",
    padding: "3px 8px",
  },
};

function Home(props) {
  const navigate = useNavigate();

  return (
    <GeneralLayout
      title="Welcome | Shri Paramhans Advait Mat (Australia)"
      heading="Welcome"
    >
      <Stack spacing={6} justifyContent="center" alignItems="center">
        <Marquee gradient={false} pauseOnHover={true} speed={40}>
          <span style={style.marquee}>
            Heartiest Congratulations to all the Gurumukhs for new Ashram in
            Sydney.
          </span>
        </Marquee>
        <SliderBlock images={landImages} />
        <Typography variant="body1" align="center" sx={style.body1}>
          Shri Arti Pooja, Satsang, Seva, Simran aur Dhyaan, shraddha se nitya
          kare, nischay ho kalyaan.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          Great Enlightened Souls and Illuminated Saints incarnate on earth from
          time to time with the mission of spiritual upliftment of mankind, to
          propagate goodness, truthfulness and righteousness in the society and
          to remove evil tendencies and shortcomings of the generation of Their
          times. Same way, His Holiness Shri Shri 108 Shri Swami Advait Anand Ji
          Maharaj, first Benign Spiritual Master incarnated in India and
          established Shri Paramhans Advait Mat.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          To take forward the objects on this holy mission of supreme devotion
          and dedication, and to carry out its religious, spiritual and
          charitable activities SHRI PARAMHANS ADVAIT MAT AUSTRALIA INC. was
          incorporated in Australia.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          Considering the number of devotees attached to SHRI PARAMHANS ADVAIT
          MAT in Australia and to attain the objects of the Association, it has
          been decided to buy and develop a new Satsang Bhawan at 167-169 Mayo
          Road, Llandilo, NSW, Australia.
        </Typography>
        <Typography variant="body2" sx={style.body2}>
          The management requests all the devotees in large, to donate some
          amount for the purchase and development of this Bhawan as a service to
          humanity, religion, spirituality and charity. For this purpose, please
          donate using the link below.
        </Typography>
        <Button
          size="large"
          variant="contained"
          onClick={() => navigate("/donate")}
        >
          Make Donation
        </Button>
        <Button
          variant="outlined"
          component={Link}
          onClick={() => navigate("/gallery")}
          sx={{ backgroundColor: "#ffffff" }}
        >
          Events Organised by SPHAM, Australia
        </Button>
        <Button
          variant="outlined"
          component={Link}
          onClick={() => navigate("/events")}
          sx={{ backgroundColor: "#ffffff" }}
        >
          Event Calendar by SPHAM, Australia
        </Button>
        <Button
          variant="outlined"
          component={Link}
          href="https://www.facebook.com/SSDNAUSTRALIA/"
          target="_blank"
          sx={{ backgroundColor: "#ffffff" }}
          startIcon={<FacebookRoundedIcon />}
        >
          Follow us on Facebook
        </Button>
      </Stack>
    </GeneralLayout>
  );
}

export default Home;
