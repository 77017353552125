import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const PaymentModal = (props) => {
  const { state, update } = props;

  const [open, setOpen] = useState(false);
  const handleClose = () => update(false);

  useEffect(() => {
    if (state) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [state]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Make Payment
          </Typography>
          <Typography id="modal-modal-description" sx={{ mb: 2 }}>
            Enter the information to finalise the payment.
          </Typography>
          {props.children}
        </Box>
      </Modal>
    </div>
  );
};
